<template>
  <div class="pbf" v-if="data">

     <GrowerTabActivity
      :grower="data.id"
      :inurl="'/growers/' + props.id + '/activity'"      
      />

  </div>
</template>

<script setup> 


const route = useRoute();
const { $api, $ga, $helper, $tagsUtil, $head } = useNuxtApp();
var grower_id = ref(route.params.id);

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  id: {
    type: Number
  }
});

// useHead(useNuxtApp().$head.getGrowerActivity(props.data));

// head() {
//     return this.$head.getGrowerActivity(this.data)
//   },


</script>

<style scoped>
 

</style>

